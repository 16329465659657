import React from "react";
import { Link } from "react-router-dom";

const BrandList = [
  {
    image: "./images/brand/axe.png",
    // style: {},
    style: { maxHeight: 200 },
  },
  {
    image: "./images/brand/Unacademy.png",
    style: { scale: "1.5" },
  },
  {
    image: "./images/brand/redbull.png",
    style: { maxHeight: 180 },
  },
  {
    image: "./images/brand/nautilus.png",
    style: { maxHeight: 100 },
  },
  {
    image: "./images/brand/Nextwavewhite.png",
    style: { maxHeight: 200 },
  },
  {
    image: "./images/brand/MPL.png",
    style: { maxHeight: 200 },
  },
  {
    image: "./images/brand/ballebazzi.png",
    style: { scale: "1.4" },
  },
  {
    image: "./images/brand/gosugamers.png",
    style: { maxHeight: 60 },
  },
  {
    image: "./images/brand/letsgamenow.png",
    style: { maxHeight: 150 },
  },
  {
    image: "./images/brand/probo.png",
    style: { maxHeight: 180 },
  },
];

const BrandTwo = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          <Link>
            <img
              style={data?.style}
              src={`${data.image}`}
              alt={`Brand ${index}`}
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default BrandTwo;
