import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterFour";

import Typed from "react-typed";
// import Slider from "react-slick";
// import { BannerActivation } from "../utils/script";
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
// import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
// import VideoItem from "../elements/video/VideoItem";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
// import landinground from "../assets/images/pexels-cowomen-2041393.jpg";
import HeaderTopBar from "../common/header/HeaderTopBar";
//image import
import bannerImage from "../assets/images/image_for_landing_screen_Ausbaun.png";
import BrandTwo from "../elements/brand/BrandTwo";

// var BlogListData = BlogClassicData.slice(0, 3);

// const BannerData = [
//   {
//     image: callground,
//     title: "Grow Your Business with a Terrific website.",
//     description: "Get your website and app built today.",
//   },
// ];

// const PopupData = [
//   {
//     id: "01",
//     image: "./images/bg/bg-image-4.jpg",
//     popupLink: [
//       "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
//     ],
//   },
// ];

const DigitalAgency = () => {
  return (
    <>
      <SEO title="Digital Agency" />
      <main className="page-wrapper">
        <HeaderTopBar />
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750 main-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-1 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two">
                    {window.innerWidth > 500 ? (
                      <>
                        Grow Your Business <br /> With{" "}
                      </>
                    ) : (
                      <span>Grow Your Business With</span>
                    )}
                    <Typed
                      className="typingkrlo"
                      strings={["Website", "Mobile App", "Social Media"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    We are offering <b>BLOOMING SALE</b> accounting upto{" "}
                    <b>20% OFF.</b>
                  </p>
                  <div className="button-group new-customer">
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      style={{ borderColor: "hsl(0deg 0% 100% / 40%)" }}
                      to="/contact"
                    >
                      New Customer
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    {/* <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      style={{ borderColor: "hsl(0deg 0% 100% / 40%)" }}
                      to="/websitescore"
                    >
                      Check Your Website Score{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-2 order-lg-2 main-photo">
                <div className="thumbnail">
                  <img src={bannerImage} alt="Banner Images" />
                </div>
              </div>

              <div className="col-lg-12 order-3 order-lg-3">
                <BrandTwo brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>

        {/* Start Brand Area  */}
        {/* <div className="rwt-brand-area pb--80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area  */}

        {/* End Slider Area  */}

        {/* Start Slider Area  */}
        {/* <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1 className="title">{data.title}</h1>
                        <p className="description">{data.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area" id="aboutUs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="About Us"
                  title="Services provided for you."
                  description="Variety of services at a single place makes us a hassleless counsultancy."
                />
              </div>
            </div>
            <ServiceFive serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        <div id="portfolio" className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Why Choose Us"
                  title="Companies Choose us!"
                  description="Following are some visually represented reasons why people choose us.  An idea of what you can expect from us, </br> and after we deliver above your expectations you will die to leave feedback below &#x2193
                  "
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay" />
          </div>
        </div>
        {/* End  portfolio Area  */}
        <Separator />

        {/* Start testimonial Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Testimonial."
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End testimonial Area  */}

        <Separator />
        {/* Start expertise Area  */}

        {/* <div className="rwt-progressbar-area rn-section-gap">
          <div className="container">
            <div className="row mb--25">
              <div className="col-lg-10 offset-lg-1">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Expertise."
                  title="Companies Expertise."
                  description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <div className="col-lg-10 offset-lg-1">
              <CircleProgress />
            </div>
          </div>
        </div> */}
        {/* Start expertise Area  */}

        {/* Start Timeline Area  */}
        {/* <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Timeline"
                  title="Working Process."
                  description="Getting started is simple."
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <TimelineOne />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Timeline Area  */}

        <Separator />
        {/* {PopupData.map((item) => (
          <div className="container" key={item.id}>
            <VideoItem galleryItem={item} />
          </div>
        ))} */}
        {/* Start Brand Area  */}
        {/* <Separator /> */}
        <div className="rwt-brand-area pb--80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="title">Brands we worked with</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}
        <Separator />

        <CalltoActionFive />

        <Separator />

        <FooterFour />
      </main>
    </>
  );
};
export default DigitalAgency;
