import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postForms } from "../../../services/general/general";

export const postContactForm = createAsyncThunk(
  "Contact/postContactForm",
  async (data) => {
    const response = await postForms(data);
    return response;
  }
);

const ContactSlice = createSlice({
  name: "Contact",
  initialState: {
    error: {},
    status: "idle",
  },
  reducers: {
    // logout: (state, action) => {
    //   state.status = "error";
    //   state.error = {};
    // },
  },
  extraReducers: {
    [postContactForm.pending]: (state, action) => {
      state.status = "Loading";
    },
    [postContactForm.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.status = action.payload.msg;
      } else {
        state.status = "error";
      }
    },
    [postContactForm.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
  },
});

// export const {} = ContactSlice.actions;
export const selectContactError = (state) => state.Contact.error;
export const selectContactStatus = (state) => state.Contact.status;

export default ContactSlice.reducer;
