import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

function Modalll({ show, setShow, onHide, setContinue }) {
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  let onModalHide = () =>
    onHide ? onHide : setShow ? setShow(false) : setModalShow(false);

  return (
    <Modal
      className="modal-back"
      show={show || modalShow}
      onHide={onModalHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ padding: "0px" }}></Modal.Header>
      <Modal.Body>
        <p>
          “ 95% people fail because they are too afraid to take the action. ”
          <br /> We know you are not one of them. Are you sure you wanna go
          back?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ padding: "15px", justifyContent: "center" }}>
        <Button
          className="btn btn-secondary btn-block ml-1"
          onClick={() => setContinue(false)}
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          I will come back later
        </Button>
        <Button
          type="button"
          className="btn btn-primary "
          onClick={onModalHide()}
        >
          I will take action
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Modalll;
