import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import Blury from "../common/Blury";
import { FiChevronUp } from "react-icons/fi";
import Modalll from "../extraelements/backmodal";
import WebsiteLink from "../extraelements/WebsiteScore/WebsiteLink";
import PersonalInfoForm from "../extraelements/WebsiteScore/personalinfoform";
import FormSubmitted from "../extraelements/WebsiteScore/formSubmitted";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function WebsiteScore() {
  let [step, setStep] = useState(0);
  let [Continue, setContinue] = useState(true);
  let [show, setShow] = useState(false);
  let modalChange = () => {
    setStep((prev) => {
      if (prev >= 1) {
        prev -= 1;
      } else {
        setShow(true);
      }
      return prev;
    });
  };

  useEffect(() => {
    if (!Continue) {
      window.location.href = "/";
    }
  }, [Continue]);

  return (
    <>
      <SEO
        // title="Check your website score here!"
        desc={
          "we check website score and provide ultimate solutions for your websites and make sure you don't need another solution"
        }
      ></SEO>
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

        <div className="rn-back-top back-arrow" onClick={modalChange}>
          <FiChevronUp />
        </div>
        <Blury />
        <Modalll
          show={show}
          setShow={setShow}
          onHide={() => setShow(false)}
          setContinue={setContinue}
        />
        {step === 0 && <WebsiteLink setStep={setStep} />}
        {step === 1 && <PersonalInfoForm setStep={setStep} />}
        {step === 2 && <FormSubmitted setStep={setStep} />}
        {/*{step === 3 && <PersonalInfoForm setStep={setStep} />} */}
        {/* <FooterFour /> */}
      </main>
    </>
  );
}

export default WebsiteScore;
