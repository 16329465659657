import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AdminAuthService from "../../../services/auth/authHepler";

export const checkLoginStatus = createAsyncThunk(
  "admin/checkLoginStatus",
  async () => {
    const response = await AdminAuthService.getCurrentUser(
      localStorage.getItem("token")
    );
    return response;
  }
);

export const loginAdmin = createAsyncThunk("admin/logInAdmin", async (data) => {
  const response = await AdminAuthService.login(data);
  return response;
});

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoggedIn: false,
    adminData: {},
    status: "idle", //loading;
    error: {},
  },
  reducers: {
    logout: (state, action) => {
      state.isLoggedIn = false;
      state.status = "error";
      state.adminData = {};
      localStorage.setItem("token", "");
    },
  },
  extraReducers: {
    [checkLoginStatus.pending]: (state, action) => {
      state.status = "Loading";
    },
    [checkLoginStatus.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        localStorage.setItem("token", action.payload.data.token);
        state.isLoggedIn = true;
        state.adminData = action.payload.data;
        state.status = "fulfilled";
      } else {
        localStorage.setItem("token", "");
        state.isLoggedIn = false;
        state.adminData = {};
        state.status = "error";
      }
    },
    [checkLoginStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
    [loginAdmin.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        localStorage.setItem("token", action.payload.data.token);
        state.status = "fulfilled";
        state.adminData = action.payload.data;
      } else {
        state.status = "error";
        localStorage.setItem("token", "");
        state.isLoggedIn = false;
        state.adminData = {};
      }
    },
    [loginAdmin.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
  },
});

export const { logout } = adminSlice.actions;
export const selectAdmin = (state) => state.admin.adminData;
export const selectStatus = (state) => state.admin.status;
export const selectIsLoggedIn = (state) => state.admin.isLoggedIn;

export default adminSlice.reducer;
