import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postNewCustomerForms } from "../../../services/general/general";

export const postNewCustomerForm = createAsyncThunk(
  "newCustomer/postNewCustomerForm",
  async (data, functions) => {
    let state = functions.getState();
    const response = await postNewCustomerForms({ ...state.newCustomer });
    return response;
  }
);

const newCustomer = createSlice({
  name: "newCustomer",
  initialState: {
    service: "",
    brief: "",
    budget: 9999,
    budgetRange: "",
    personalInfo: {
      name: "",
      email: "",
      contactNo: "",
      whatsappNo: "",
    },
    errors: "errors",
  },
  reducers: {
    addService: (state, action) => {
      state.service = action.payload;
    },

    resetState: (state, action) => {
      state = {
        service: "",
        brief: "",
        budget: 9999,
        budgetRange: "",
        personalInfo: {
          name: "",
          email: "",
          contactNo: "",
          whatsappNo: "",
        },
        errors: "errors",
      };
    },

    addBrief: (state, action) => {
      state.brief = action.payload;
    },

    addBudget: (state, action) => {
      const { budget, range } = action.payload;
      state.budget = budget;
      state.budgetRange = range;
    },

    addPersonalInfo: (state, action) => {
      let { name, email, contactNo, whatsappNo } = action.payload;
      state.personalInfo = { name, email, contactNo, whatsappNo };
      newCustomer.caseReducers.checkData(state, action);
    },

    checkData: (state, action) => {
      let {
        service,
        personalInfo: { name, email, contactNo, whatsappNo },
      } = state;
      if (service) {
        if (name && email && contactNo) {
          var pattern = new RegExp(/^[0-9\b]+$/);

          if (pattern.test(contactNo) && contactNo.length === 10) {
            if (whatsappNo) {
              if (pattern.test(whatsappNo) && whatsappNo.length === 10) {
                state.errors = "";
              } else {
                state.errors = "Enter Valid Mobile Number.";
              }
            } else {
              state.errors = "";
            }
          } else {
            state.errors = "Enter Valid Mobile Number.";
          }
        } else {
          state.errors = "Enter Complete Personal Info!";
        }
      } else {
        state.errors = "Enter service!";
      }
    },
  },
  extraReducers: {
    [postNewCustomerForm.pending]: (state, action) => {
      state.status = "Loading";
    },
    [postNewCustomerForm.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.status = action.payload.msg;
      } else {
        state.status = "error";
      }
    },
    [postNewCustomerForm.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
  },
});

export const { addService, addBrief, addBudget, addPersonalInfo, resetState } =
  newCustomer.actions;

export const selectService = (state) => state.newCustomer.service;
export const selectBudget = (state) => state.newCustomer.budget;
export const selectBudgetRange = (state) => state.newCustomer.budgetRange;
export const selectBrief = (state) => state.newCustomer.brief;
export const selectPersonalInfo = (state) => state.newCustomer.personalInfo;
export const selectNewCustomerErrors = (state) => state.newCustomer.errors;

export default newCustomer.reducer;
