import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
// import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
// import { FiChevronUp } from "react-icons/fi";
// import Blury from "../../common/Blury";

const Contact = () => {
  return (
    <>
      <SEO title="Contact || Ausbaun Web Development Busniess" />
      <Layout>
        {/* <BreadcrumbOne
          title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        /> */}
        <div className="main-content">
          {/* Start Contact Area  */}
          <div
            className="rwt-contact-area rn-section-gap"
            style={{ padding: "40px 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contact Form"
                    title="Our Contact Address Here."
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
