import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useDispatch } from "react-redux";
import { postContactForm } from "../../store/features/general/contact";
import { Alert } from "@mui/material";
import Snackbar from "../../extraelements/snackbar/snackbar";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  let dispatch = useDispatch();

  const [result, setResult] = useState("");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { email, phoneNo };

    if (email && phoneNo) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (pattern.test(phoneNo) && phoneNo.length === 10) {
        submitForm({ email, phoneNo, name, message });
      } else {
        setResult("Enter A Valid Phone Number");
        setOpen(true);
      }
    } else {
      setResult("error");
      setOpen(true);
    }
  };

  const submitForm = async (e) => {
    let res = await dispatch(
      postContactForm({ name, email, message, phoneNo })
    );
    console.log(res.payload);
    if (res.payload.status === 200) {
      setResult("success");
      setEmail("");
      setName("");
      setMessage("");
      setPhoneNo("");
      setOpen(true);
    } else {
      setResult("Some error occurred!");
      setOpen(true);
    }
  };

  // useEffect(() => {

  // });

  return (
    <form className={`${formStyle}`} action="" onSubmit={handleSubmit}>
      <Snackbar
        message={
          result === "success"
            ? "We will reach out as soon as Possible"
            : result
        }
        severity={result === "success" ? "success" : "error"}
        show={open}
        location={{ vertical: "bottom", horizontal: "center" }}
        setShow={setOpen}
      />
      <div className=" row mt--40 row--15">
        <div className="col-lg-7">
          <div className="form-group">
            <input
              type="text"
              name="fullname"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* <p>{name}</p> */}
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* <p>{email}</p> */}
          </div>

          <div className="form-group">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="col-lg-5">
          <div className="form-group">
            <textarea
              style={{ height: "100%" }}
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            {/* <p>{message}</p> */}
          </div>
        </div>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      {/* <div className="form-group">{result ? <Result /> : null}</div> */}
    </form>
  );
}
export default ContactForm;
