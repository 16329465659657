import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
  {
    form: "India",
    description: `It was our immense pleasure to work with you ‘Ausbaun’. You bring a lot of creativity and zest to the table. You are very personable, responsive, and results-oriented! Would love to associate with you again.`,
    name: "Abhishek",
    subtitle: "Nautilus Mobile",
    image: "abhistan.jpeg",
  },
  {
    form: "India",
    description:
      "Working with them was a great experience. They always respond politely to our needs. They have a strong vision and always get the job done.",
    name: "Pushpal Bag",
    subtitle: "Let's Game Now",
    image: "pushpa.jpeg",
  },
  {
    form: "India",
    description:
      "It was amazing working with you Smoothly we operated the campaign with you.",
    name: "Kshitij Sharma",
    subtitle: "The Pyromedia",
    image: "pyromedia.png",
  },
  {
    form: "India",
    description:
      "Quality of Deliverables was extra ordinary. Worth every penny spent!",
    name: "Jordan",
    subtitle: "Navela Industries",
    image: "jordan.jpeg",
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <span className="form">{data.form}</span>
                    <p className="description">{data.description}</p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/testimonial/${data.image}`}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
