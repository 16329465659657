import { combineReducers } from "redux";
import adminReducer from "./features/admin/adminSlice";
import ContactReducer from "./features/general/contact";
import newCustomer from "./features/general/newCustomer";
import WebsiteScore from "./features/general/WebsiteScore";

export default combineReducers({
  admin: adminReducer,
  Contact: ContactReducer,
  newCustomer: newCustomer,
  WebsiteScore: WebsiteScore,
});
