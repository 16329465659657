import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterFour";
import TypesOfService from "../extraelements/newCustomer/typeofservice";
import PersonalInfoForm from "../extraelements/newCustomer/personalinfoform";
import WorkDetailsForm from "../extraelements/newCustomer/WorkDetailsForm";
import Blury from "../common/Blury";
import { FiChevronUp } from "react-icons/fi";
import PricingSection from "../extraelements/newCustomer/pricingSection";
import Modalll from "../extraelements/backmodal";
import { useHistory, useLocation } from "react-router-dom";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function Form({ show, setShow }) {
  const [Continue, setContinue] = useState(true);
  const [step, setStep] = useState(0);

  let history = useHistory(),
    currentPathname = null,
    currentSearch = null;

  // [show, setShow] = useState(false);

  let modalChange = () => {
    setStep((prev) => {
      if (prev >= 1) {
        prev -= 1;
      } else {
        setShow(true);
      }
      return prev;
    });
  };
  console.log(Continue);
  useEffect(() => {
    let unlisten = history.listen((newLocation, action) => {
      if (action === "PUSH") {
        setShow(false);

        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;
          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else if (action === "POP") {
        if (Continue) {
          setShow(true);
          history.go(1);
        }
      }
    });
    if (!Continue) {
      window.location.href = "/";
      return unlisten;
    }
  }, [Continue]);

  return (
    <>
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

        <div className="rn-back-top back-arrow" onClick={modalChange}>
          <FiChevronUp />
        </div>
        <Blury />
        <Modalll
          show={show}
          setShow={setShow}
          onHide={() => setShow(false)}
          setContinue={setContinue}
        />
        {step === 0 && <TypesOfService setStep={setStep} />}
        {step === 1 && <WorkDetailsForm setStep={setStep} />}
        {step === 2 && <PricingSection setStep={setStep} />}
        {step === 3 && <PersonalInfoForm setStep={setStep} />}
        {/* <FooterFour /> */}
      </main>
    </>
  );
}

export default Form;
