import { Snackbar as Base } from "@mui/material";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import Grow from "@mui/material/Grow";
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GrowTransition(props) {
  return <Grow {...props} />;
}

const Snackbar = ({
  message,
  show,
  result,
  setShow,
  location,
  transition,
  severity,
}) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (result === "success") {
      history.push("/");
    } else {
      if (reason === "clickaway") {
        return;
      }
      setShow ? setShow(false) : setOpen(false);
    }
  };

  return (
    <>
      <Base
        onClick={() => {
          if (result === "success") {
            history.push("/");
          }
        }}
        anchorOrigin={
          location
            ? { vertical: location.vertical, horizontal: location.horizontal }
            : { vertical: "bottom", horizontal: "left" }
        }
        TransitionComponent={transition ? transition : GrowTransition}
        open={show ? show : open}
        autoHideDuration={2200}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity ? severity : "error"}
          sx={{ width: "100%", fontSize: "1.5rem" }}
        >
          {message ? message : "This is an error message!"}
        </Alert>
      </Base>
    </>
  );
};

export default Snackbar;
