import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import SectionTitle from "../sectionTitle/SectionTitle";
import style from "../../assets/scss/style.scss";
//import hooks
import { useState } from "react";
// import { Typography } from "@mui/material";

//img import
import pp1 from "../../assets/images/pp1.png";
import pp2 from "../../assets/images/pp2.png";
import pp3 from "../../assets/images/pp3.png";
import pp4 from "../../assets/images/pp4.png";

//gifs import
import gif1 from "../../assets/gifs/coding.gif";
import gif2 from "../../assets/gifs/gcse-computer.gif";
import gif3 from "../../assets/gifs/programming-computer.gif";
import gif4 from "../../assets/gifs/typing-laptop.gif";
import {
  addBudget,
  selectBudget,
} from "../../store/features/general/newCustomer";
import { useDispatch, useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

export default function PriceSlider({ btnStyle, setStep }) {
  // const [value, setValue] = useState(9999);
  let dispatch = useDispatch();
  let value = useSelector(selectBudget);

  const marks = [
    {
      value: 9000,
      label: "₹9999",
    },
    {
      value: 29999,
      label: "₹29999",
    },
    {
      value: 49999,
      label: "₹49999",
    },
    {
      value: 99999,
      label: "₹99999",
    },
    {
      value: 149999,
      label: "₹149999",
    },
    {
      value: 299999,
      label: "₹299999",
    },
  ];

  const valuetext = (value) => {
    if (value < 9000) {
      return "Less than OR ₹9000";
    } else if (value > 9000 && value < 29999) {
      return "₹9999-₹29999";
    } else if (value > 29999 && value < 49999) {
      return "₹29999-₹49999";
    } else if (value > 49999 && value < 99999) {
      return "₹49999-₹99999";
    } else if (value > 99999 && value < 149999) {
      return "₹99999-₹149999";
    } else if (value > 149999 && value <= 299999) {
      return "₹149999-₹299999";
    } else {
      return `₹${value}`;
    }
  };

  const gifs = (value) => {
    if (value === 999) {
      return <img className={style.gif1} src={gif1} alt="gif1" />;
    } else if (value === 999 + 1500) {
      return <img src={gif2} alt="gif2" />;
    } else if (value === 999 + 3000) {
      return <img src={gif3} alt="gif3" />;
    } else {
      return <img src={gif4} alt="gif4" />;
    }
  };
  const suggestions = (value) => {
    if (value === 999) {
      return <img src={pp1} alt="pp1" />;
    } else if (value === 999 + 1500) {
      return <img src={pp2} alt="pp2" />;
    } else if (value === 999 + 3000) {
      return <img src={pp3} alt="pp3" />;
    } else {
      return <img src={pp4} alt="pp4" />;
    }
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      // setValue(newValue);
      dispatch(addBudget({ budget: newValue, range: valuetext(value) }));
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="sliderParent">
          <div className="sliderHeadDiv">
            <h1 className="sliderTopHead">Your Budget Range!</h1>
            <p className="pricerange">{valuetext(value)}</p>
          </div>

          <Slider
            value={value}
            valueLabelDisplay="on"
            aria-label="Temperature"
            getAriaValueText={valuetext}
            step={100}
            size="medium"
            marks={marks}
            onChange={handleChange}
            min={8999}
            max={299999}
            sx={{
              "&.MuiSlider-colorPrimary": {
                color: "#5989fc ",
              },
            }}
          />
        </div>

        {/* <div
          className="header-btn"
          style={{
            // position: "relative",
            // left: "530px",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
        > */}
        {/* <Link
            to="#"
            className={`btn-default ${btnStyle}`}
            onClick={() => setStep((prev) => prev + 1)}
          >
            CONFIRM
          </Link> */}
        {/* </div> */}
        {/* gifffffffffffffffffffff start */}
        {/* <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Item>{gifs(value)}</Item>
          </Grid> */}
        {/* gifffffffffffffffffffff enddddddddddd */}

        {/* <Grid item xs={6} md={6}>
            <Item sx={{ color: "#fff" }}>{suggestions(value)}</Item>
          </Grid> */}
        {/* </Grid> */}
      </Box>
    </>
  );
}
