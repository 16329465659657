import React from "react";
import { Link } from "react-router-dom";
import consultancy from "../../assets/images/consultancy.png";
import development from "../../assets/images/development.png";
import design from "../../assets/images/design.png";

import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: design,
    title: "Design",
    description: "Visual Identity ~ Dressing your business up.",
  },
  {
    image: consultancy,
    title: "Web & App development",
    description: "Life is too short for ordinary apps.",
  },
  {
    image: development,
    title: "Media Creation/Long Form",
    description: "Quality Media for Maximum Retentions",
  },
];
const ServiceFive = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
                <div className="image">
                  <img src={`${val.image}`} alt="card Images" />
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFive;
