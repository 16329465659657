import React from "react";
import { Link } from "react-router-dom";
import consultancy from "../../assets/images/consultancy.png";
import development from "../../assets/images/development.png";
import design from "../../assets/images/design.png";
import { useDispatch } from "react-redux";
import { addService } from "../../store/features/general/newCustomer";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ServiceList = [
  {
    image: design,
    title: "Branding",
    description: "Logo, Visiting Card, Vectors arts",
  },
  {
    image: consultancy,
    title: "Development",
    description: "Websites, iOS & Android App.",
  },
  {
    image: development,
    title: "Social Media",
    description: "Video Editing, Thumbnail Design, Content Strategy.",
  },
  {
    image: development,
    title: "Photography",
    description: "Product photography, potraits.",
  },
];
const ImageCheckBox = ({ textAlign, serviceStyle, setStep }) => {
  let dispatch = useDispatch();

  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div
          onClick={() => {
            console.log(val.title);
            dispatch(addService(val.title));
            setStep(1);
          }}
          className="col-lg-6 col-md-6 col-sm-12 col-12"
          key={i}
        >
          <div className={`service imagecheckbox ${serviceStyle} ${textAlign}`}>
            <div className="inner content">
              <span className="title">
                <Link to="#">{val.title}</Link>
              </span>
              <p className="description">{val.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ImageCheckBox;
