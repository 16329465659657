import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postWebsiteScoreForms } from "../../../services/general/general";

export const postWebsiteScoreForm = createAsyncThunk(
  "WebsiteScore/postWebsiteScoreForm",
  async (data, functions) => {
    let state = functions.getState();
    const response = await postWebsiteScoreForms({ ...state.WebsiteScore });
    return response;
  }
);

const WebsiteScore = createSlice({
  name: "WebsiteScore",
  initialState: {
    url: "",
    name: "",
    email: "",
    contactNo: "",
    id: "",
    errors: "errors",
  },
  reducers: {
    addUrl: (state, action) => {
      state.url = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    addPersonalInfo: (state, action) => {
      let { name, email, contactNo } = action.payload;
      state.name = name;
      state.email = email;
      state.contactNo = contactNo;
      WebsiteScore.caseReducers.checkData(state, action);
    },
    checkData: (state, action) => {
      let { url, name, email, contactNo } = state;
      if (url && name && email && contactNo) {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (pattern.test(contactNo) && contactNo.length === 10) {
          state.errors = "";
        } else {
          state.errors = "Enter Valid Mobile Number.";
        }
      } else {
        state.errors = "Enter Data!";
      }
    },
  },
  extraReducers: {
    [postWebsiteScoreForm.pending]: (state, action) => {
      state.status = "Loading";
    },
    [postWebsiteScoreForm.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.status = action.payload.msg;
      } else {
        state.status = "error";
      }
    },
    [postWebsiteScoreForm.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
  },
});

export const { addPersonalInfo, addUrl, setId } = WebsiteScore.actions;

export const selectUrl = (state) => state.WebsiteScore.url;
export const selectId = (state) => state.WebsiteScore.id;
export const selectWebsiteScore = (state) => state.WebsiteScore;
export const selectName = (state) => state.WebsiteScore.name;
export const selectEmail = (state) => state.WebsiteScore.email;
export const selectContactNo = (state) => state.WebsiteScore.contactNo;
export const selectWebsiteScoreErrors = (state) => state.WebsiteScore.errors;

export default WebsiteScore.reducer;
