import axios from "axios";
import instance from "../instance";

export const postForms = async (obj) => {
  try {
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/45327190/eee12287-94dd-4482-b9bf-51aad02b363a";
    let data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: "desc",
          value: obj.message,
        },
        {
          name: "email",
          value: obj.email,
        },
        {
          name: "firstname",
          value: obj.name,
        },
        {
          name: "phone",
          value: obj.phoneNo,
        },
      ],
      context: {
        pageUri: "www.ausbaun.com",
        pageName: "Ausbaun",
      },
    };

    let res = await instance({
      method: "post",
      url,
      data,
    });

    return res;
  } catch (error) {
    console.log(error);
    return { status: 400 };
  }
};

export const postNewCustomerForms = async (obj) => {
  try {
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/45327190/79bce9a0-52ef-4c7d-95ca-7ccdfa83fa66";
    let data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: "service",
          value: obj.service,
        },
        {
          name: "brief",
          value: obj.brief,
        },
        {
          name: "budget",
          value: obj.budget,
        },
        {
          name: "budgetrange",
          value: obj.budgetRange,
        },
        {
          name: "contantno",
          value: obj.personalInfo.contactNo,
        },
        {
          name: "whatsappno",
          value: obj.personalInfo.whatsappNo,
        },
        {
          name: "firstname",
          value: obj.personalInfo.name,
        },
        {
          name: "email",
          value: obj.personalInfo.email,
        },
      ],
      context: {
        pageUri: "www.ausbaun.com",
        pageName: "Ausbaun",
      },
    };

    let res = await axios({
      method: "post",
      url,
      data,
    });

    return res;
  } catch (error) {
    console.log(error);
    return { status: 400 };
  }

  // let final_data = JSON.stringify(data);
  // submitForm(url, data);

  // let { data } = await instance({
  //   method: "post",
  //   url: "newCustomer",
  //   data: obj,
  // });
};

export const postWebsiteScoreForms = async (obj) => {
  let { data } = await instance({
    method: "post",
    url: "websitescore",
    data: obj,
  });
  return data;
};
