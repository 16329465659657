import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import PricingCustom from "../../elements/pricing/PricingCustom";
import PriceSlider from "../../elements/pricing/PriceSlider";
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Blury from "../../common/Blury";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

const PricingSection = ({ btnStyle, setStep }) => {
  const [require, setRequire] = useState();

  return (
    <>
      <div className="rn-service-area rn-section-gap pricing-section">
        <div className="container">
          <div className="row">
            <div
              // style={{ padding: "0 15rem" }}
              className="col-lg-12 main-title"
            >
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="The More The Merrier"
                title="Set your budget."
              />
              <p style={{ textAlign: "center" }}>
                Money Back Guarantee. <Link>T&C apply</Link>{" "}
              </p>
            </div>

            <Form className="col-lg-12 main-content pricing">
              <Form.Group>
                <div style={{ margin: "0 auto 6rem" }}>
                  <PriceSlider />
                </div>
                <div className="header-btn">
                  <Link
                    to="#"
                    className={`btn-default ${btnStyle}`}
                    onClick={() => setStep((prev) => prev + 1)}
                  >
                    CONFIRM
                  </Link>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSection;
