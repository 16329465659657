import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import {
  addUrl,
  selectUrl,
  selectWebsiteScore,
} from "../../store/features/general/WebsiteScore";

function WebsiteLink({ setStep, formStyle, btnStyle }) {
  let dispatch = useDispatch();
  let url = useSelector(selectUrl);

  return (
    <div className="rn-service-area rn-section-gap typeofservice">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 main-title">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="Website Score Checker"
              title="Enter Your Website/App URL"
            />
            <p style={{ textAlign: "center" }}>
              Make sure you're the rightful owner of the WEBSITE/APPLICATION{" "}
              <br />
              We review that before scoring.
            </p>
          </div>
          <div className="col-lg-12 main-content personal-info">
            <form className={`${formStyle ? formStyle : ""}`} action="">
              <div
                className="form-group personal-info"
                style={{ textAlign: "center" }}
              >
                <input
                  className="website-link"
                  type="text"
                  name="url"
                  placeholder="Enter URL here!"
                  value={url}
                  onChange={(e) => dispatch(addUrl(e.target.value))}
                  required
                />
              </div>

              <div className="header-btn" onClick={() => setStep(1)}>
                <button type="button" className={`btn-default ${btnStyle}`}>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteLink;
