import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addBrief,
  selectBrief,
} from "../../store/features/general/newCustomer";

function WorkDetailsForm({ btnStyle, setStep }) {
  let dispatch = useDispatch();
  let brief = useSelector(selectBrief);

  return (
    <>
      <div className="rn-service-area rn-section-gap work-details-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-title">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="A step towards growth"
                title="Briefly Describe Your Requirements"
              />
            </div>
            <Form className="col-lg-12 main-content mb--80">
              <p
                style={{
                  textAlign: "center",
                }}
              >
                {" "}
                People don't understand the value of describing and if you want
                to describe on call skip this step.
              </p>
              <Form.Group controlId="formdescriptionarea">
                <textarea
                  name="message"
                  placeholder="Your requirements"
                  required
                  value={brief}
                  onChange={(e) => {
                    dispatch(addBrief(e.target.value));
                  }}
                />
              </Form.Group>
              <div
                className="header-btn"
                style={{
                  position: "relative",
                  left: "10px",
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Link
                  to="#"
                  className={`btn-default ${btnStyle}`}
                  onClick={() => setStep((prev) => prev + 1)}
                >
                  NEXT
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkDetailsForm;
