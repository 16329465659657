import React, { useState } from "react";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../snackbar/snackbar";
import {
  postWebsiteScoreForm,
  selectContactNo,
  selectEmail,
  selectName,
  selectWebsiteScoreErrors,
  addPersonalInfo,
  setId,
} from "../../store/features/general/WebsiteScore";

function PersonalInfoForm({ btnStyle, formStyle, setStep }) {
  const [result, setResult] = useState("");
  const [open, setOpen] = useState(false);

  let dispatch = useDispatch();
  let name = useSelector(selectName);
  let email = useSelector(selectEmail);
  let contactNo = useSelector(selectContactNo);
  let errors = useSelector(selectWebsiteScoreErrors);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors) {
      submitForm();
    } else {
      setResult(errors);
      setOpen(true);
    }
  };

  let submitForm = async () => {
    let res = await dispatch(postWebsiteScoreForm());
    if (res.payload.statusCode === 200) {
      dispatch(setId(res.payload.id));
      setStep(2);
    } else {
      setResult("Some Error Occured!");
      setOpen(true);
    }
  };

  return (
    <div className="rn-service-area rn-section-gap personal-info-form">
      <Snackbar
        result={result}
        message={
          result === "success"
            ? "We will reach out as soon as Possible"
            : result
        }
        severity={result === "success" ? "success" : "error"}
        show={open}
        location={{ vertical: "bottom", horizontal: "center" }}
        setShow={setOpen}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 main-title">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="A step towards growth"
              title="Your Information"
            />
          </div>
          <div className="col-lg-12 main-content personal-info">
            <form
              className={`${formStyle ? formStyle : ""}`}
              action=""
              onSubmit={handleSubmit}
            >
              <div
                className="form-group personal-info"
                style={{
                  maxWidth: "70rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <input
                  type="text"
                  name="fullname"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) =>
                    dispatch(
                      addPersonalInfo({
                        name: e.target.value,
                        email,
                        contactNo,
                      })
                    )
                  }
                  required
                />
              </div>

              <div
                className="form-group personal-info"
                style={{
                  maxWidth: "70rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) =>
                    dispatch(
                      addPersonalInfo({
                        name,
                        email: e.target.value,
                        contactNo,
                      })
                    )
                  }
                  required
                />
              </div>

              <div
                className="form-group personal-info"
                style={{
                  maxWidth: "70rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <input
                  type="text"
                  name="phone"
                  placeholder="Contact Number"
                  value={contactNo}
                  onChange={(e) =>
                    dispatch(
                      addPersonalInfo({
                        name,
                        email,
                        contactNo: e.target.value,
                      })
                    )
                  }
                  required
                />
              </div>

              <div className="header-btn">
                <button type="submit" className={`btn-default ${btnStyle}`}>
                  Submit now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PersonalInfoForm;
