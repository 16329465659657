import React from "react";
import HeaderTwo from "./header/HeaderTwo";
import FooterFour from "./footer/FooterFour";

const Layout = ({ children }) => {
  return (
    <>
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        <FooterFour />
      </main>
    </>
  );
};
export default Layout;
