import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ExContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_p4x3hv8",
        "template_jgfr42f",
        e.target,
        "user_jrfTH2e0Ely35ZCVFdT9S"
      )
      .then(
        (result) => {
        },
        (error) => {
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <textarea
          name="message"
          placeholder="Briefly describe your requirements"
          required
        ></textarea>
      </div>

      <div className="form-group">
        <input type="email" name="email" placeholder="Email ID" required />
      </div>

      <div className="form-group">
        <label></label>
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>
      <div className="form-group">
        <input type="text" name="phone" placeholder="WhatsappNumber" required />
      </div>
      <div className="form-group">
        <input type="text" name="phone" placeholder="Contact Number" required />
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ExContactForm;
