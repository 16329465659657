import React from "react";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import ImageCheckBox from "../../elements/service/ImageCheckBox";

function TypesOfService({ setStep }) {
  return (
    <div className="rn-service-area rn-section-gap typeofservice">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 main-title">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="A step towards growth"
              title="What services you are interested in?"
            />
          </div>
          <div className="col-lg-12 main-content">
            <ImageCheckBox
              setStep={setStep}
              serviceStyle="gallery-style "
              textAlign="text-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypesOfService;
