import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import PricingCustom from "../../elements/pricing/PricingCustom";
import PriceSlider from "../../elements/pricing/PriceSlider";
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Blury from "../../common/Blury";
import { useSelector } from "react-redux";
import { selectId } from "../../store/features/general/WebsiteScore";

// const Result = () => {
//   return (
//     <p className="success-message">
//       Your Message has been successfully sent. I will contact you soon.
//     </p>
//   );
// };

const FormSubmitted = ({ btnStyle, setStep }) => {
  const id = useSelector(selectId);
  return (
    <>
      <div className="rn-service-area rn-section-gap pricing-section">
        <div className="container">
          <div className="row">
            <div
              // style={{ padding: "0 15rem" }}
              className="col-lg-12 main-title"
              style={{ textAlign: "center" }}
            >
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Wait till we revert back."
                title="We are processing your Application."
              />
              <p style={{ color: id ? "inherit" : "red" }}>
                Application ID: {id ? id : "! Error Form Not Submitted"}
              </p>
              <img src="images/greentick.png" style={{ width: "27rem" }}></img>
            </div>
            <div className="col-lg-12 main-content personal-info">
              <div className="header-btn">
                <Link to="/" className={`btn-default ${btnStyle}`}>
                  Go Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSubmitted;
