import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { Link } from "react-router-dom";

const HeaderTwo = ({ btnStyle, HeaderStyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderStyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="d-lg-none col-md-1 col-1 position-static">
              <div className="mobile-menu-bar d-block d-lg-none">
                <div className="hamberger">
                  <span className="hamberger-button" onClick={onCanvasHandler}>
                    <FiMenu />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/ausbaunLogo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/ausbaunLogo.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-7 header-left">
              <div className="header-right" style={{ margin: "0" }}>
                <div className="header-btn ">
                  <Link
                    to="/contact"
                    className={`btn-default ${btnStyle} getstarted-main`}
                    style={{
                      // background: "transparent",
                      // fontWeight: "Bold",
                      fontSize: "1.7rem",
                      // boxShadow: "none",
                      // padding: "0",
                    }}
                  >
                    Get Started
                  </Link>
                </div>
                <div className="header-btn">
                  {/* <Link
                    to="/websitescore"
                    className={`btn-default ${btnStyle}`}
                  >
                    Check Your Website Score!
                  </Link> */}
                </div>

                {/* <Darkmode /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderTwo;
